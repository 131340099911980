/**
 * Colors
 */

$white: #fff;
$black: #000;

// Secondary Colour Palette
$dying-red: #dc493a;
$mango-tango: #ff7f40;
$butter-canary: #ffb71b;
$caribbean-green: #00c08b;
$aqua-dream: #4cc8ed;
$ocean-boat-blue: #0075bf;
$midnight-blue: #1c355e;
$epic-purple: #60269e;

// Neutral Colour Palette
$pure-snow: #fafbfc;
$white-smoke: #f6f7f9;
$milky-ivory: #eef0f3;
$athens-grey: #e6e9ed;
$scented-pearl: #dadfe4;
$musical-oyster: #d2d8df;
$polar-snow: #bcc4cf;
$artful-ivory: #a6b1c0;
$simmering-bay: #909eb0;
$shadow-blue: #7a8aa0;
$made-steel: #647790;
$blue-bayoux: #4e6481;
$dazed-cobalt: #385071;
$legal-royal: #223d61;
// $midnight-blue: #1c355e;
$space-cadet: #0c2a52;
$oxford-blue: #091f3c;
$black-pearl: #07172d;

// Extended Colour Palette
$spring-linen: #fbeeed;
$sea-pink: #efaca5;
$pink-salmon: #e88b81;
$rose-garden: #e26a5d;
$wild-passion: #dc493a;
$roasting-wine: #b53c30;
$modern-rust: #8c2f25;

$misty-rose: #ffe7dc;
$fresh-peach: #ffc4a8;
$vivid-tangerine: #ffad85;
$atomic-tangerine: #ff9662;
// $mango-tango: #ff7f40;
$lazy-citron: #e8743b;
$dreamy-cinnamon: #ba5d2f;

$seashell: #fff6e4;
$lemon-meringue: #ffeabc;
$vanila-sky: #ffe1a2;
$lemon-squeeze: #ffd16d;
$textured-citron: #ffc444;
// $butter-canary: #ffb71b;
$golden-hour: #e8a719;

$iceburg: #d0f3e9;
$minty-morning: #8be2ca;
$fresh-leaf: #5cd6b5;
$mountain-meadow: #2ecba0;
// $caribbean-green: #00c08b;
$bouncing-green: #009e72;
$bottle-green: #00694c;

$sea-froth: #def5fb;
$blue-lagoon: #bdebf8;
$morning-dew: #8ddcf3;
$sky-spray: #6cd2f0;
// $aqua-dream: #4cc8ed;
$soft-sapphire: #3fa4c2;
$joyful-calypso: #2a6e82;

$early-sky: #e7f2f9;
$first-tide: #b9d9ed;
$melting-icicle: #8bc0e1;
$carolina-blue: #5ca7d6;
$ocean-boat-blue: #0075bf;
$royal-peacock: #00568b;
$midnight-blue: #1c355e;

$cloudy-ivory: #d9cce8;
$east-flower: #b499d1;
$lavender-haze: #9974c1;
$mellow-studio: #7c4daf;
// $epic-purple: #60269e;
$honey-eggplant: #461c73;
$russian-violet: #2c1248;

