/**
 * Buttons
 */

$btn-blue-bg: $ocean-boat-blue !default;
$btn-green-bg: $caribbean-green !default;
$btn-text-color: $white !default;
$btn-font-weight: inherit !default;
$btn-font-size: 16px !default;
$btn-padding-x: 40px !default;
$btn-border-color: transparent !default;
$btn-border-width: 0 !default;
$btn-border-radius: 0;
$btn-height: 40px !default;

$btn-hover-text-color: white !default;
$btn-hover-border-color: transparent !default;

$btn-sm: .9 !default;
$btn-lg: 1.2 !default;

.btn {
  display: inline-block;
  padding: 8px 40px;
  border: $btn-border-width solid transparent;
  background-color: $btn-blue-bg;
  color: $btn-text-color;
  vertical-align: middle;
  text-align: center;
  white-space: nowrap;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: $transition-base;
  user-select: none;
  text-decoration: none;
  border-radius: $btn-border-radius;
  height: $btn-height;
  -webkit-appearance: none;

  &:hover,
  &:focus {
    text-decoration: none;
  }

  &:hover {
    background-color: $royal-peacock;
    color: $btn-hover-text-color;
    border-color: $btn-hover-border-color;
  }

  &:focus {
    outline: 0;
  }

  &.btn-green {
    background-color: $btn-green-bg;
    &:hover {
      background-color: $bouncing-green;
    }
  }

  &.btn-disabled {
    background-color: $musical-oyster;
    &:hover {
      background-color: $musical-oyster;
    }
  }
}

// Size Variants
.btn-sm {
  font-size: 14px;
  padding: 9px 30px;
  height: 32px;
}

.btn-lg {
  font-size: 20px;
  height: 48px;
}

// Button Block
.btn-block {
  display: block;
  width: 100%;
}

// Button Outline
.btn-secondary {
  border: 1px solid $btn-blue-bg;
  background-color: transparent;
  color: $btn-blue-bg;
  &:hover {
    border-color: $btn-blue-bg;
    background-color: $btn-blue-bg;
    color: $btn-hover-text-color;
  }

  &.btn-green {
    border: 1px solid $btn-green-bg;
    background-color: transparent;
    color: $btn-green-bg;
    &:hover {
      border-color: $btn-green-bg;
      background-color: $btn-green-bg;
      color: $btn-hover-text-color;
    }
  }

  &.btn-disabled {
    border: 1px solid $musical-oyster;
    background-color: transparent;
    color: $musical-oyster;
    &:hover {
      border: 1px solid $musical-oyster;
      background-color: transparent;
      color: $musical-oyster;
    }
  }

}

.btn-delete {
  border: none;
  background-color: $wild-passion;
  &:hover {
    background-color: $roasting-wine;
    color: $btn-hover-text-color;
  }

  &.btn-disabled {
    background-color: $musical-oyster;
    &:hover {
      background-color: $musical-oyster;
      color: $btn-hover-text-color;
    }
  }

}

// Button Link
.btn-link {
  box-shadow: none;
  background: transparent;
  border: 0;
  color: black;
  text-shadow: none;
  text-transform: none;

  &:hover,
  &:focus {
    background: transparent;
    color: black;
  }
}
