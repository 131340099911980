/* Customize the label (the container) */
.btn.dropdown-toggle {
  border-radius: 3px;
  width: 100%;
  border-color: $polar-snow;
  text-align: left;
  font-size: 16px;
  color: $oxford-blue;
  height: 52px;
  padding-left: 30px;
  padding-right: 20px;
  font-weight: normal;

  &:after {
    float: right;
    margin-top: 10px;
  }

  &:hover,
  &:active,
  &:visited {
    border-width: 1px;
    color: $oxford-blue;
    border-color: $simmering-bay;
  }

  &:hover {
    background-color: $milky-ivory;
  }
}
