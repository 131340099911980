/* Customize the label (the container) */
.labelContainer {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-align: left;
}

/* Hide the browser's default checkbox */
.labelContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 22px;
  width: 22px;
  background-color: #fafbfc;
  border: 1px solid #bcc4cf;
  border-radius: 3px;
}

/* On mouse-over, add a grey background color */
.labelContainer:hover input ~ .checkmark {
  background-color: #fafbfc;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.labelContainer input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.labelContainer .checkmark:after {
  left: 4px;
  top: 4px;
  width: 12px;
  height: 10px;
  background-image: url('https://assets.lawpath.com/html/tick.svg');
}
