/**
 * Paragraph
 */

p {
  font-size: 16px;
  line-height: 27px;
  font-weight: normal;
  margin-top: 0;
  margin-bottom: 4px;
}

small,
.small {
  font-size: 12px;
  line-height: 20px;
  font-weight: normal;
}
