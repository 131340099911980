/**
 * Document defaults (html, body)
 */

html {
  font-family: $font-family;
  font-size: $font-size-base;
  color: $black;
  line-height: $line-height-base;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}
