/**
 * Global
 *
 * !default Flag
 * When building a library, a framework, a grid system or any piece of Sass
 * that is intended to be distributed and used by external developers,
 * all configuration variables should be defined with the !default flag
 * so they can be overwritten.
 */

$font-family: 'Geomanist', Arial, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
  "Helvetica Neue", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;
$font-size-base: 1rem !default;
$form-group-margin-bottom: 1rem !default;
$form-text-margin-top: 1rem !default;
$line-height-base: 1.5 !default;
