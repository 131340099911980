/**
 * Section
 */

.section {
}

.section-accordion {
  border: 1px solid $musical-oyster;
  border-bottom: none;
  & > div {
    & > div:first-child,
    & > div > div {
      border-bottom: 1px solid $musical-oyster;
    }
    & > div:first-child {
      background-color: $white-smoke;
      padding: 10px;
    }
    & > .section-info > div {
      padding: 10px;
    }
  }
}
