/**
 * Footer
 */

#lawpath-footer.footer {
  color: #fff;

  .container {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .logo{
    height: 45px;
  }

  .social {
    display: inline-block;
    img {
      height: 40px;
      width: 40px;
      margin-right: 5px;
    }
    &:hover {
      img.monochrome {
        display: none;
      }
      img.colour {
        display: block;
      }
    }
    img.monochrome {
      display: block;
    }
    img.colour {
      display: none;
    }
  }

  .lawpath-url {
    color: #fff;
    font-size: 14px;
    display: block;
  }
  a.color-white {
    &:hover {
      color: #fff;
    }
  }
}
