@for $i from 1 through 10 {
  #tab#{$i} ~ #section#{$i} {
    display: none;
  }

  #tab#{$i}:checked ~ #section#{$i} {
    display: inline-block;
    padding-top: 50px;
  }

  #tab#{$i}:checked + #label#{$i} {
    background: #fff;
    border-top: 3px solid $ocean-boat-blue;
  }
  #tab#{$i} + #label#{$i} {
    padding: 15px 50px;
    background: $musical-oyster;
    display: block;
    float: left;
    text-align: center;
    border-top: 3px solid $musical-oyster;
  }
}

.tabs {
  display: none;
}

.section {
  padding: 15px 50px;
}
