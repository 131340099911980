/**
 * Font Face
 */

@font-face {
  font-family: 'Geomanist';
  src:
    url('https://assets.lawpath.com/fonts/Geomanist-Book.eot?#iefix') format('embedded-opentype'),
    url('https://assets.lawpath.com/fonts/Geomanist-Book.woff') format('woff'),
    url('https://assets.lawpath.com/fonts/Geomanist-Book.ttf') format('truetype'),
    url('https://assets.lawpath.com/fonts/Geomanist-Book.svg#Geomanist-Book') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Geomanist';
  src:
    url('https://assets.lawpath.com/fonts/Geomanist-Medium.eot?#iefix') format('embedded-opentype'),
    url('https://assets.lawpath.com/fonts/Geomanist-Medium.woff') format('woff'),
    url('https://assets.lawpath.com/fonts/Geomanist-Medium.ttf') format('truetype'),
    url('https://assets.lawpath.com/fonts/Geomanist-Medium.svg#Geomanist-Medium') format('svg');
  font-weight: bold;
  font-style: normal;
}
