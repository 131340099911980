/**
 * Headings
 */

$headings-font-family: $font-family;
$headings-font-weight: 700;
$headings-line-height: 1.2;
$headings-color: inherit;

$h1-font-size: 42px;
$h2-font-size: 32px;
$h3-font-size: 26px;
$h4-font-size: 20px;
$h5-font-size: 16px;
$h6-font-size: 12px;

$h1-line-height: 64px;
$h2-line-height: 50px;
$h3-line-height: 41px;
$h4-line-height: 33px;
$h5-line-height: 27px;
$h6-line-height: 20px;


h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-top: 0;
  margin-bottom: 0;
  font-family: $headings-font-family;
  font-weight: $headings-font-weight;
  color: $headings-color;
}

@include media-breakpoint-down(sm) {
  h1,
  .h1 {
    font-size: 33px;
    line-height: 52px;
  }
  h2,
  .h2 {
    font-size: 27px;
    line-height: 42px;
  }
  h3,
  .h3 {
    font-size: 23px;
    line-height: 34px;
  }
  h4,
  .h4 {
    font-size: 19px;
    line-height: 27px;
  }
  h5,
  .h5 {
    font-size: 15px;
    line-height: 22px;
  }
  h6,
  .h6 {
    font-size: 11px;
    line-height: 16px;
  }
  p {
    font-size: 16px;
    line-height: 26px;
  }
  small {
    font-size: 11px;
    line-height: 22px;
  }
}

